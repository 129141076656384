import request from '@/utils/request.js'

//用户登录
export function postLogin(data) {
  return request({
    url: 'api/v1/login',
    method: 'post',
    data: data
  })
}
//用户注册
export function postNewUser(data) {
  return request({
    url: 'api/v1/register',
    method: 'post',
    data: data
  })
}
//用户验证

export function postVerifyCode(data) {
  return request({
    url: 'api/v1/register/verification',
    method: 'post',
    data: data
  })
}
// yiminwei
// Zstume@17247
