<template>
  <div class="login">
    <ScaleScreen :width="1920" :height="1080" class="scale-wrap">
      <div class="login-header">
        <div class="header-inner">
          <div class="header-title">
            <span>电梯安全防控云平台</span>
          </div>
        </div>
      </div>
      <dv-border-box-1 class="border-box">
        <div class="login-main">
          <div class="main-left">
            <div class="main-left-title">欢迎登录</div>
            <div class="main-left-form">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                class="demo-ruleForm"
              >
                <el-form-item label="" prop="uname">
                  <el-input
                    placeholder="请输入用户名"
                    v-model="ruleForm.uname"
                    prefix-icon="el-icon-user"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="pwd">
                  <el-input
                    placeholder="请输入密码"
                    v-model="ruleForm.pwd"
                    show-password
                    prefix-icon="el-icon-unlock"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="login-btn" @click="login('ruleForm')">登录</div>
              <div class="register" @click="register">没有账号？点击注册</div>
            </div>
          </div>
          <div class="main-right">
            <img src="../../assets/img/earth.png" alt="" />
          </div>
        </div>
      </dv-border-box-1>

      <div class="login-footer"></div>
    </ScaleScreen>
  </div>
</template>

<script>
import ScaleScreen from '@/components/scale-screen/scale-screen.vue'
import { postLogin } from '@/api/login.js'

export default {
  components: { ScaleScreen },

  data() {
    return {
      ruleForm: {
        uname: '',
        pwd: ''
      },
      rules: {
        uname: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            pattern: /^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$/,
            trigger: 'blur',
            message: '用户名格式不正确！'
          }
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            pattern: /^[\w@!#%&,.?+]{6,20}$/,
            trigger: 'blur',
            message: '密码格式不正确！'
          }
        ]
      }
    }
  },
  created() {},
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postLogin(this.ruleForm).then((res) => {
            this.$message.success('登录成功！')
            this.$store.commit('setRightList', res.rights)
            this.$store.commit('setUid', res.uid)
            this.$store.commit('setUsername', res.uname)
            localStorage.setItem('token', res.token)
            this.$router.push('/')

            //获取用户管理设备相关数据
            // getDeviceData({ id: parseInt(that.uid) }).then(function (res) {
            //   console.log(res);
            //   that.$store.commit('setDeviceList', res.data)
            //   that.$store.commit('setCurrentDevice', res.data[0].id)
            //   that.$store.commit(
            //     'setAudioDate',
            //     new Date().toLocaleDateString().split('/').join('-')
            //   )
            //   that.$store.commit(
            //     'setVibrationDate',
            //     new Date().toLocaleDateString().split('/').join('-')
            //   )
            //   that.$store.commit(
            //     'setCurrentDate',
            //     new Date().toLocaleDateString().split('/').join('-')
            //   )

            //   that.$store.commit('setAudioSelectedAlg', 1)
            //   that.$store.commit('setVibrationSelectedAlg', 1)
            //   that.$store.commit('setCurrentSelectedAlg', 1)

            //   that.$store.commit('setCurrentSelectedType', 1)
            // })
          })
        } else {
          return false
        }
      })
    },
    register() {
      this.$router.push('/register')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url('../../assets/img/loginbg.png') center center;
  background-size: cover;
  background-repeat: no-repeat;
  .login-header {
    width: 100%;
    height: 105px;
    background: url('../../assets/img/header.png') no-repeat center center;
    background-size: cover;
    position: absolute;
    top: 0;
    .header-inner {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;
      padding: 32px 45px 19px 210px;
      position: relative;
    }
    .header-title {
      width: 100%;
      height: 48px;
      position: absolute;
      text-align: center;
      background-size: cover;
      color: transparent;
      left: 0;
      span {
        height: 48px;
        font-family: PingFangSC;
        letter-spacing: 5px;
        font-style: normal;
        text-align: center;
        font-size: 38px;
        font-weight: 900;
        letter-spacing: 6px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(30, 208, 248, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .border-box {
    width: 900px;
    height: 466px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: linear-gradient(
      0deg,
      rgba(7, 15, 36, 0.4) 0%,
      rgba(7, 15, 36, 0.4) 50%,
      rgba(1, 21, 69, 0.4) 100%
    );
  }

  .login-main {
    width: 100%;
    display: flex;
    padding: 5.46% 1.46% 5.46% 2.5%;

    .main-left {
      width: 459px;
      padding: 0 5.65%;
      .main-left-title {
        /* 欢迎登陆 */
        height: 45px;
        color: #63e2ff;
        font-weight: 400;
        font-size: 32px;
        letter-spacing: 1px;
        text-align: center;
      }
      .main-left-form {
        width: 100%;
        margin-top: 40px;
        ::v-deep .el-input__icon {
          color: #63e2ff;
          font-size: 20px;
          width: 40px;
        }
        ::v-deep .el-input--prefix .el-input__inner {
          padding-left: 47px;
        }
      }
      .login-btn {
        width: 338px;
        height: 58px;
        background: url('../../assets/img/login-btn.png');
        background-repeat: no-repeat;
        margin-top: 80px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        line-height: 54px;
        letter-spacing: 1px;
        cursor: pointer;
      }
      .register {
        text-align: center;
        color: #63e2ff;
        line-height: 46px;
        cursor: pointer;
      }
    }
    .main-right {
      width: 378px;
      height: 350px;
      img {
        width: 378px;
        height: 350px;
      }
    }
  }
}
</style>
